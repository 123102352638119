import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import { BURNETT_API_ENDPOINT } from '../../config';
import Button from '../../components/generic/button';
import './profile-plans.scss';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51P3nqj06RKiEhBnGleUG5XsZvczyLckGPdqrSoELuwfuKXMlRQzyVyT7cTHmZjueww6Y1p83k2WzCTtyzo0wF9Ih005eX2lIuv');

function ProfilePlans(userProfile) {
  const navigate = useNavigate();
  const { user, getIdTokenClaims } = useAuth0();
  const [userSubType, setUserSubType] = useState();
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    if (!userProfile) return;
    console.log(userProfile);
    const { profileData } = userProfile;
    const {
      sub_type = "NO_SUB",
      email
    } = profileData;
    setUserSubType(sub_type);
    setUserEmail(email);
  }, [userProfile]);

  const onClickBuy = async (planType) => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      
      const stripe = await stripePromise;

      // Call the backend to create a checkout session
      const response = await axios.post(`${BURNETT_API_ENDPOINT}/stripe/create-checkout-session`, {
        planType,
      },{
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error("Stripe checkout error:", error.message);
        toast.error("Something went wrong with the checkout process. Please try again.");
      }
    } catch (err) {
      console.error("API error:", err);
      toast.error("Failed to initiate the checkout process. Please try again.");
    }
  };

  if (userEmail !== "sebastian.roberts@testunidev.edu") {
    return (
      <>{userEmail}</>
    )
  }

  return (
    <div className="profile-plans-container">
      {userSubType}
      <h2>Pick a plan and start your free trial today!</h2>
      <div className="profile-plan-cards-container">
        <div className="profile-plan-card student">
          <h3>STUDENT</h3>
          <p className="price">$59 <span>per-year</span></p>
          <ul>
            <li>Annual membership</li>
            <li>7-day free trial</li>
            <li>Unlimited pitch practice sessions</li>
            <li>AI coach</li>
            <li>Team feedback</li>
            <li>Interactive transcript</li>
            <li>One competition entry fee included*</li>
          </ul>
          <Button text="Buy" onClick={() => onClickBuy("STUDENT")} className="primary plan-buy-button" />
        </div>

        <div className="profile-plan-card pro">
          <h3>PRO</h3>
          <p className="price">$139 <span>per-year</span></p>
          <ul>
            <li>Annual membership</li>
            <li>7-day free trial</li>
            <li>Unlimited pitch practice sessions</li>
            <li>AI Coach</li>
            <li>Team feedback</li>
            <li>Interactive transcript</li>
            <li>Unlimited competition access*</li>
          </ul>
          <Button text="Buy" onClick={() => onClickBuy("PRO")} className="primary plan-buy-button" />
        </div>

        <div className="profile-plan-card event-organizer">
          <h3>EVENT ORGANIZER</h3>
          <p className="price">$2,500 <span>per-year</span></p>
          <ul>
            <li>Easily create and manage competitions</li>
            <li>7-day free trial</li>
            <li>Unlimited video pitches</li>
            <li>Unlimited teams</li>
            <li>AI coach API integration</li>
            <li>Competition manager</li>
            <li>Rewards and payout manager</li>
          </ul>
          <Button text="Buy" onClick={() => onClickBuy("ORGANIZER")} className="primary plan-buy-button" />
        </div>
      </div>
    </div>
  );
}

export default ProfilePlans;
