import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '../../components/generic/button';
import Popup from '../../components/generic/popup';
import ProfileFormContainer from '../../containers/profileForm';
import { BURNETT_API_ENDPOINT } from '../../config';
import './header.scss';

function Header({ isAuthenticated, logout, loginWithRedirect, user, getIdTokenClaims, isLoadingComplete, userProfileData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [userId, setUserId] = useState(null);
  const [competitionLogo, setCompetitionLogo] = useState(null);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [showPrimaryLogoClass, setShowPrimaryLogoClass] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const handleProfileClick = () => {
    setShowEditProfile(!showEditProfile);
  };

  const handleCompeteClick = () => {
    window.location.href = "https://compete.longjump.ai";
  };

  const handlePracticeClick = () => {
    window.location.href = "https://practice.longjump.ai";
  }

  useEffect(() => {
    if (!loadingComplete) return;
    isLoadingComplete();
  }, [loadingComplete]);

  const getUserProfileSettings = async () => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;
      const response = await axios.get(`${BURNETT_API_ENDPOINT}/get-profile`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const data = response.data;
      setUserProfile(data);

    } catch (err) {}
  }

  useEffect(() => {
    if (!competitionLogo) {
      setShowPrimaryLogoClass(true);
      const timer = setTimeout(() => {
        setShowPrimaryLogoClass(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [competitionLogo]);

  useEffect(() => {
    if (!loadingComplete) return;
  }, [loadingComplete, location]);

  useEffect(() => {
    if (!user) return;
    const { email } = user;
    getUserProfileSettings(email);
  }, [user]);

  useEffect(() => {
    if (!user || !userProfile) return;
    const { custom_first_name = "", tenant_admin = false } = userProfile;
    localStorage.setItem('custom_first_name', custom_first_name);
    localStorage.setItem('tenant_admin', tenant_admin);
    setLoadingComplete(true);
  }, [userProfile])

  useEffect(() => {
    if (!user || !userProfile) return;
    userProfileData(userProfile);
  }, [userProfile])

  useEffect(() => {
    if (!isAuthenticated && window.location.href !== "http://localhost:3000/" && window.location.href !== "http://localhost:3000/profile-plans") {
      window.location.href = "https://id.longjump.ai";
    }
  }, [isAuthenticated]);

  const getName = (profileData) => {
    const {
      given_name,
      family_name,
      custom_picture,
      custom_first_name,
      custom_last_name,
      email,
      user_id
    } = profileData;

    if (!userId && user_id) setUserId(user_id);
  
    const firstName = custom_first_name;
    const lastName = custom_last_name;

    user.custom_picture = custom_picture;
  
    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    } else {
      return email;
    }
  }

  useEffect(() => {
    setIsMobileMenuActive(false);
  }, [location.pathname]);

  const handleViewProfile = () => {
    if (!userId) return;
    window.location.href = `https://compete.longjump.ai/profile/${userId}`;
  }

  if (isAuthenticated) {
    return (
      <>
        {showEditProfile ? (
          <Popup isOpen={showEditProfile} onClose={handleProfileClick}>
            <ProfileFormContainer />
          </Popup>
        ) : <></>}
        <div className="header-container">
          <div className="header-container-inner">

            <div className="user-container">
              <b>{userProfile ? getName(userProfile) : ""}</b>
              <div className='profile-picture-container'>
                <div className='hover-arrow' />
                <div className='profile-picture' style={{backgroundImage: `url(${user.custom_picture})`}}>
                <div className='view-profile-click-area' onClick={() => handleViewProfile()}/>

                  <div className="hover-card">
                    <h3>ACCOUNT SETTINGS</h3>
                    <ul>
                      <li onClick={handleProfileClick}>&#x2022; &nbsp; Profile</li>
                      <li onClick={() => logout({ logoutParams: { returnTo: window.location.href === "http://localhost:3000/" ? "http://localhost:3000" : "https://id.longjump.ai" }})}>
                        &#x2022; &nbsp; Sign out
                      </li>
                    </ul>
                    <div className="app-nav">
                      <div className="app-nav-item compete-button" onClick={handleCompeteClick}>
                        <div className="app-nav-icon compete-icon" />
                        Compete
                      </div>
                      <div className="app-nav-item practice-button" onClick={handlePracticeClick}>
                        <div className="app-nav-icon practice-icon" />
                        Practice
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
       window.location.href === "http://localhost:3000/" ? (
        <div className="header-container">
          <div className="header-container-inner">
            <div className="org-icon-container" />
            <Button text="Sign Up" onClick={loginWithRedirect} className="secondary signup-button" />
            <Button text="Log in" onClick={loginWithRedirect} className="primary login-button" />
          </div>
        </div>
        ) : <></>
    );
  }
}

export default Header;
