import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from "react-toastify";
import { BURNETT_API_ENDPOINT } from '../../config';
import Button from '../../components/generic/button';
import './profile-plans.scss';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51P3nqj06RKiEhBnGleUG5XsZvczyLckGPdqrSoELuwfuKXMlRQzyVyT7cTHmZjueww6Y1p83k2WzCTtyzo0wF9Ih005eX2lIuv');

function ProfilePlans(userProfile) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const { user, getIdTokenClaims } = useAuth0();
  const [userSubType, setUserSubType] = useState();
  const [userSubExpiresAt, setUserSubExpiresAt] = useState();
  const [userEmail, setUserEmail] = useState(null);
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userProfile) return;
    const { profileData } = userProfile;
    const {
      stripe_sub_type = "NO_SUB",
      stripe_sub_expires_at = null,
      email
    } = profileData;
    setUserSubType(stripe_sub_type);
    setUserSubExpiresAt(stripe_sub_expires_at);
    setUserEmail(email);
  }, [userProfile]);

  const getStripeSession = async () => {
    setLoading(true);
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const response = await axios.get(
        `${BURNETT_API_ENDPOINT}/stripe/retrieve-checkout-session`,
        {
          params: { sessionId },
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setCheckoutDetails(response.data);
    } catch (err) {
      console.error("API error:", err);
      toast.error("Failed to retrieve the checkout session. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkoutDetails) {
      window.location.reload();
    }
  }, [checkoutDetails]);

  useEffect(() => {
    if (sessionId) {
      getStripeSession();

      // Remove session_id from the URL
      searchParams.delete("session_id");
      setSearchParams(searchParams);
      navigate(window.location.pathname, { replace: true });
    }
  }, [sessionId, navigate, searchParams, setSearchParams]);

  const onClickBuy = async (planType) => {
    try {
      const claims = await getIdTokenClaims();
      const idToken = claims.__raw;

      const stripe = await stripePromise;

      // Call the backend to create a checkout session
      const response = await axios.post(`${BURNETT_API_ENDPOINT}/stripe/create-checkout-session`, {
        planType,
      }, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json"
        }
      });

      const { sessionId } = response.data;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error("Stripe checkout error:", error.message);
        toast.error("Something went wrong with the checkout process. Please try again.");
      }
    } catch (err) {
      console.error("API error:", err);
      toast.error("Failed to initiate the checkout process. Please try again.");
    }
  };

  const getPlanButtonText = (currentPlan, planType) => {
    if (currentPlan === "NO_SUB") return "Buy";
  
    const planOrder = ["NO_SUB", "STUDENT_SUB", "PRO_SUB", "ORGANIZER_SUB"];
    const currentPlanIndex = planOrder.indexOf(currentPlan);
    const planTypeIndex = planOrder.indexOf(planType);
  
    if (currentPlanIndex === planTypeIndex) return "Active";
    if (currentPlanIndex < planTypeIndex) return "Upgrade";
    return "Buy";
  };

  const getPlanDescription = (userSubType) => {
    switch (userSubType) {
      case "NO_SUB":
        return "Pick a plan and start your free trial today!";
      case "STUDENT_SUB":
        return "You are currently on the Student plan.";
      case "PRO_SUB":
        return "You are currently on the Pro plan.";
      case "ORGANIZER_SUB":
        return "You are currently on the Event Organizer plan.";
      default:
        return "Pick a plan and start your free trial today!";
    }
  };

  if (loading) {
    return <div className="loading-plans"></div>;
  }

  if (userEmail !== "sebastian.roberts@testunidev.edu") {
    return <>{userEmail}</>;
  }

  return (
    <div className="profile-plans-container">
      <h2>{getPlanDescription(userSubType)}</h2>
      <div className="profile-plan-cards-container">
        <div className="profile-plan-card student">
          <h3>STUDENT</h3>
          <p className="price">$59 <span>per-year</span></p>
          <ul>
            <li>Annual membership</li>
            <li>7-day free trial</li>
            <li>Unlimited pitch practice sessions</li>
            <li>AI coach</li>
            <li>Team feedback</li>
            <li>Interactive transcript</li>
            <li>One competition entry fee included*</li>
          </ul>
          <Button
            text={getPlanButtonText(userSubType, "STUDENT_SUB")}
            onClick={userSubType === "STUDENT_SUB" ? null : () => onClickBuy("STUDENT")}
            className={`primary plan-buy-button ${userSubType === "STUDENT_SUB" ? "active-sub-button" : ""}`}
          />
          {userSubExpiresAt && userSubType === "STUDENT_SUB" ? <span>Renews: {userSubExpiresAt}</span> : <></>}
        </div>
  
        <div className="profile-plan-card pro">
          <h3>PRO</h3>
          <p className="price">$139 <span>per-year</span></p>
          <ul>
            <li>Annual membership</li>
            <li>7-day free trial</li>
            <li>Unlimited pitch practice sessions</li>
            <li>AI Coach</li>
            <li>Team feedback</li>
            <li>Interactive transcript</li>
            <li>Unlimited competition access*</li>
          </ul>
          <Button
            text={getPlanButtonText(userSubType, "PRO_SUB")}
            onClick={userSubType === "PRO_SUB" ? null : () => onClickBuy("PRO")}
            className={`primary plan-buy-button ${userSubType === "PRO_SUB" ? "active-sub-button" : ""}`}
          />
          {userSubExpiresAt && userSubType === "PRO_SUB" ? <span>Renews: {userSubExpiresAt}</span> : <></>}
        </div>
  
        <div className="profile-plan-card event-organizer">
          <h3>EVENT ORGANIZER</h3>
          <p className="price">$2,500 <span>per-year</span></p>
          <ul>
            <li>Easily create and manage competitions</li>
            <li>7-day free trial</li>
            <li>Unlimited video pitches</li>
            <li>Unlimited teams</li>
            <li>AI coach API integration</li>
            <li>Competition manager</li>
            <li>Rewards and payout manager</li>
          </ul>
          <Button
            text={getPlanButtonText(userSubType, "ORGANIZER_SUB")}
            onClick={userSubType === "ORGANIZER_SUB" ? null : () => onClickBuy("ORGANIZER")}
            className={`primary plan-buy-button ${userSubType === "ORGANIZER_SUB" ? "active-sub-button" : ""}`}
          />
          {userSubExpiresAt && userSubType === "ORGANIZER_SUB" ? <span>Renews: {userSubExpiresAt}</span> : <></>}
        </div>
      </div>
    </div>
  );
}

export default ProfilePlans;
